import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
	FaAward,
	FaEnvelope,
	FaFacebookF,
	FaFilm,
	FaImdb,
	FaInfoCircle,
	FaInstagram,
	FaMapMarkerAlt,
	FaPhoneAlt,
	FaStar,
} from "react-icons/fa";

import React from "react";
import Slider from "react-slick";

function Profile() {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					dots: false, // Disable dots on mobile
				},
			},
		],
	};

	// Sample Filmography Data
	const filmographyData = [
		{
			title: "Last EXIT",
			status: "In postproduction",
			year: 2024,
			roles: ["Director of Photography"],
			classification: "Feature Film",
		},
		{
			title: "Personazh",
			status: "In postproduction",
			year: 2024,
			roles: ["Director of Photography", "Editor"],
			classification: "Feature Film",
		},
		{
			title: "Karma",
			status: "In postproduction",
			year: 2024,
			roles: ["Director of Photography"],
			classification: "Feature Film",
		},
		{
			title: "Lëkura e krokodillit",
			status: "",
			year: 2024,
			roles: ["Director of Photography"],
			classification: "Feature Film",
		},
		{
			title: "Kangjelat",
			status: "In postproduction",
			year: 2023,
			roles: ["Director of Photography"],
			classification: "Feature Film",
		},
		{
			title: "Te Berberi – Wächst ja wieder (TV Series SRF)",
			status: "",
			year: 2023,
			roles: ["Director of Photography", "Editor"],
			classification: "TV Series",
		},
		{
			title: "Aktoret e kanes",
			status: "",
			year: 2023,
			roles: ["Director of Photography", "Editor", "Co-Producer"],
			classification: "Feature Documentary",
		},
		{
			title: "Winter Fireflies",
			status: "",
			year: 2022,
			roles: ["Director of Photography"],
			classification: "Feature Film",
		},
		{
			title: "Reconciliation",
			status: "",
			year: 2021,
			roles: ["Additional Editor", "Director of Photography", "Co-Producer"],
			classification: "Feature Documentary",
		},
		{
			title: "A child",
			status: "",
			year: 2021,
			roles: ["Editor", "Director of Photography"],
			classification: "Short Film",
		},
		{
			title: "Fireflies",
			status: "",
			year: 2021,
			roles: ["Director of Photography"],
			classification: "Short Film",
		},
		{
			title: "The hug of destiny",
			status: "",
			year: 2020,
			roles: ["Editor", "Director of Photography"],
			classification: "Feature Documentary",
		},
		{
			title: "Bolero ne shtepine e pleqve",
			status: "",
			year: 2020,
			roles: ["Director of Photography"],
			classification: "Feature Film",
		},
		{
			title: "TE BERBERI (TV Series, 8 Episodes)",
			status: "",
			year: 2019,
			roles: ["Director", "Director of Photography", "Editor", "Co-Producer"],
			classification: "TV Series",
		},
		{
			title: "Forgotten mountain",
			status: "",
			year: 2019,
			roles: ["Director of Photography", "Co-Producer"],
			classification: "Feature Film",
		},
		{
			title: "Here",
			status: "",
			year: 2018,
			roles: ["Director of Photography"],
			classification: "Short Film",
		},
		{
			title: "Rules of Art",
			status: "",
			year: 2018,
			roles: ["Director of Photography", "Editor", "Co-Producer"],
			classification: "Short Film",
		},
		{
			title: "Memory",
			status: "",
			year: 2017,
			roles: ["Director of Photography"],
			classification: "Short Film",
		},
		{
			title: "Forgive me",
			status: "",
			year: 2017,
			roles: ["Director of Photography"],
			classification: "Short Film",
		},
		{
			title: "Tihotapci identitete",
			status: "",
			year: 2016,
			roles: ["Director of Photography", "RTV Slovenia"],
			classification: "Feature Documentary",
		},
		{
			title: "Home",
			status: "",
			year: 2016,
			roles: ["Director of Photography"],
			classification: "Short Film",
		},
		{
			title: "Home Sweet Home",
			status: "",
			year: 2016,
			roles: ["Director of Photography"],
			classification: "Feature Film",
		},
		{
			title: "1Euro",
			status: "",
			year: 2016,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "Memory",
			status: "",
			year: 2016,
			roles: ["Director of Photography", "Editor", "Co-Producer"],
			classification: "Short Film",
		},
		{
			title: "A drop of blood",
			status: "",
			year: 2015,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "Breath",
			status: "",
			year: 2015,
			roles: ["Director of Photography"],
			classification: "Short Film",
		},
		{
			title: "Stine Dashurie (TV Series, 23 Episodes)",
			status: "",
			year: "2014-2015",
			roles: ["Director of Photography", "Editor"],
			classification: "TV Series",
		},
		{
			title: "Marihuana Republic",
			status: "",
			year: 2014,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "Amel",
			status: "",
			year: 2014,
			roles: ["Director of Photography"],
			classification: "Short Film",
		},
		{
			title: "Njëri nga ata",
			status: "",
			year: 2013,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "Mulliri",
			status: "",
			year: 2011,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "BODRUMI",
			status: "",
			year: 2011,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "In Memoriam",
			status: "",
			year: 2010,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "Tri Dritare",
			status: "",
			year: 2010,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "Gjini",
			status: "",
			year: 2010,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "Muzgu",
			status: "",
			year: 2010,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "Matanë Lumit",
			status: "",
			year: 2009,
			roles: ["Director of Photography", "Editor"],
			classification: "Short Film",
		},
		{
			title: "Gomarët e Kufirit",
			status: "",
			year: 2009,
			roles: ["Ass Camera & Film Loader"],
			classification: "Feature Film",
		},
		{
			title: "Unë jam drejtor",
			status: "",
			year: 2007,
			roles: ["Director", "Director of Photography", "Editor"],
			classification: "Short Film",
		},
	];

	// New Awards & Festivals Data
	const awardsData = [
		"Best Music – Nine Eleven Dedicated Festival, Pristina, Kosovo",
		"Best Script – Nine Eleven Dedicated Festival, Pristina, Kosovo",
		"Best Picture – Nine Eleven Dedicated Festival, Pristina, Kosovo",
		"Best Directing – Nine Eleven Dedicated Festival, Pristina, Kosovo",
		"Best Film – Nine Eleven Dedicated Festival, Pristina, Kosovo",
		"Best Cinematography – SWI-KOS Film Festival, Basel, Switzerland",
		"Best International Short – New York International Independent Film Festival, New York",
		"Best Cinematographer of the Year – 'Rudolf Sopi' Award by Radio Television of Kosova",
	];

	return (
		<div className="min-h-screen bg-gray-50">
			{/* Main Content */}
			<div className="max-w-screen-xl mx-auto px-6 py-16">
				{/* Introduction Section */}
				<section className="flex flex-col lg:flex-row items-start justify-between lg:space-x-16 mb-16">
					{/* About Text */}
					<div className="lg:w-1/2 w-full">
						<h2 className="text-4xl font-extrabold text-gray-800 mb-4">
							Hello!
						</h2>
						<h3 className="text-4xl font-bold text-gray-800 mb-6">
							I’m Latif Hasolli.
						</h3>

						{/* Social Media Icons */}
						<div className="flex items-center space-x-6 mb-6">
							<a
								href="https://www.facebook.com/latif.hasolli/"
								className="text-gray-600 hover:text-gray-800 text-3xl transition-colors duration-300"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Facebook"
							>
								<FaFacebookF />
							</a>
							<a
								href="https://www.instagram.com/latifhasolli/"
								className="text-gray-600 hover:text-gray-800 text-3xl transition-colors duration-300"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Instagram"
							>
								<FaInstagram />
							</a>
							<a
								href="https://www.imdb.com/name/nm3740039/"
								className="text-gray-600 hover:text-gray-800 text-3xl transition-colors duration-300"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="IMDb"
							>
								<FaImdb />
							</a>
						</div>

						<div className="space-y-6 text-lg text-gray-700 leading-relaxed">
							<p>
								Latif Hasolli was born in Bardh i Madh, Fushë Kosovë, Kosovo. He
								is considered to be one of the most promising professionals in
								local and international cinematography.
							</p>
							<p>
								He studied cinematography at the “Gjon Mili” Institute of
								Photography and Film in Prishtina, Kosovo.
							</p>
							<p>
								Then Film and TV Editing at the University of Tetova, Macedonia
								(Faculty of Arts). He completed his postgraduate studies at the
								FDU Academy of Fine Arts in Skopje, northern Macedonia (Film and
								TV Editing).
							</p>

							{/* Contact Details */}
							<div className="mt-8 space-y-4">
								<div className="flex items-center">
									<FaEnvelope className="text-gray-600 text-xl mr-3" />
									<p className="text-gray-700">
										<a
											href="mailto:latif.hasolli@uni-pr.edu"
											className="hover:underline"
										>
											latif.hasolli@uni-pr.edu
										</a>
									</p>
								</div>
								<div className="flex items-center">
									<FaPhoneAlt className="text-gray-600 text-xl mr-3" />
									<p className="text-gray-700">
										<a href="tel:+38344227470" className="hover:underline">
											+ (383) 44 227-470
										</a>
									</p>
								</div>
								<div className="flex items-center">
									<FaMapMarkerAlt className="text-gray-600 text-xl mr-3" />
									<p className="text-gray-700">Prishtina, Kosovo 10000</p>
								</div>
							</div>
						</div>
					</div>

					{/* Profile Image */}
					<div className="lg:w-1/2 w-full text-center lg:text-right mt-10 lg:mt-0">
						<a href="/profile.jpg" target="_blank" rel="noopener noreferrer">
							<img
								src="/profile.jpg"
								alt="Latif Hasolli"
								className="w-full lg:w-auto lg:max-w-md h-auto mx-auto lg:ml-auto rounded-2xl shadow-2xl transform hover:scale-105 transition-transform duration-300"
							/>
						</a>
					</div>
				</section>

				{/* About Section */}
				<section className="mb-16 max-w-screen-xl mx-auto">
					<h3 className="text-3xl font-bold mb-8 text-gray-800 flex items-center">
						<FaInfoCircle className="mr-3 text-2xl text-blue-500" />
						About
					</h3>
					<div className="space-y-6 text-lg text-gray-700 leading-relaxed">
						<p>
							He has lectured as an assistant at the State University of Tetova,
							Film Editing and TV course from 2012 to 2017. Now he works as
							Professor assistant in the Faculty of Dramatic Arts, Film and TV
							Editing at the Public University of Prishtina, Kosova.
						</p>
						<p>
							In addition to his artistic career in the field of film, he has
							also contributed to many work forums, jury member in film
							festivals, master classes and has given a great contribution in
							helping, training, and supporting the new generations.
						</p>
						<p>
							He has worked on over 30 short films, feature films,
							documentaries, such as Film Editor and TV, Director of Photography
							and Producer. The success has followed him continuously, and most
							recently in 2021 having an extraordinary success, as the world
							premiere of the feature documentary "Reconciliation" at the
							CPH:DOX which is considered the most prestigious festival in the
							world. 
							<a
								href="https://www.reconciliation-documentary.com"
								className="text-blue-500 hover:underline"
							>
								https://www.reconciliation-documentary.com
							</a>
						</p>
						<p>
							He is the founder of the film company "Dera Film" based in
							Pristina, also the founder of the film company "LH FILM" based in
							Tirana, these production companies produce feature films and
							documentaries, short films, etc.
						</p>
						<p>
							So, the aim is with special emphasis to develop and support the
							vision and the specific expression of young authors.
						</p>
					</div>
				</section>

				{/* Project Highlights Carousel */}
				<section className="mb-16 max-w-screen-xl mx-auto">
					<h3 className="text-3xl font-bold mb-8 text-gray-800 flex items-center">
						<FaFilm className="mr-3 text-2xl text-blue-500" />
						Project Highlights
					</h3>
					<Slider
						{...settings}
						className="dots-mobile:hidden" // Add a custom class for controlling the dots visibility
					>
						{[
							"/imm.png",
							"/imm2.png",
							"/imm3.png",
							"/IMG_0668 (1).jpg",
							"/IMG_0886.jpg",
							"/IMG_0888.GIF",
							"/IMG_3072.jpg",
							"/IMG_3079.JPG",
							"/IMG_5097.jpg",
							"/IMG_5104.jpg",
							"/IMG_6309.jpg",
							"/IMG_6317.jpg",
							"/IMG_6415.jpg",
						].map((src, index) => (
							<div className="px-4" key={index}>
								<div className="bg-white rounded-2xl shadow-lg overflow-hidden">
									<img
										src={src}
										alt={`Project highlight ${index + 1}`}
										className="w-full sm:h-[400px] md:h-[500px] lg:h-[680px] h-auto object-cover"
									/>
								</div>
							</div>
						))}
					</Slider>
				</section>

				{/* Awards and Recognition Section */}
				<section className="mb-16 max-w-screen-xl mx-auto">
					<h3 className="text-3xl font-bold mb-8 text-gray-800 flex items-center">
						<FaAward className="mr-3 text-2xl text-green-500" />
						Awards & Festivals
					</h3>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						{awardsData.map((award, index) => (
							<div
								key={index}
								className="flex items-start bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow duration-300"
							>
								<FaAward className="text-green-500 text-2xl mr-4 mt-1" />
								<p className="text-gray-700">{award}</p>
							</div>
						))}
					</div>
				</section>

				{/* Filmography Section */}
				<section className="mb-16 max-w-screen-xl mx-auto">
					<h3 className="text-3xl font-bold mb-8 text-gray-800 flex items-center">
						<FaFilm className="mr-3 text-2xl text-purple-500" />
						Filmography
					</h3>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						{filmographyData.map((film, index) => (
							<div
								key={index}
								className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow duration-300 flex flex-col justify-between"
							>
								<div>
									<h4 className="text-xl font-semibold text-gray-800 mb-2">
										{film.title}
									</h4>
									<div className="flex flex-wrap items-center mb-2">
										{/* Classification */}
										{film.classification && (
											<span className="inline-block bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full mr-2">
												{film.classification}
											</span>
										)}
										{/* Status */}
										{film.status && (
											<span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full mr-2">
												{film.status}
											</span>
										)}
										{/* Year */}
										<span className="text-sm text-gray-600">/ {film.year}</span>
									</div>
									{/* Roles */}
									<div className="flex flex-wrap items-center">
										{film.roles.map((role, idx) => (
											<span
												key={idx}
												className="flex items-center text-sm text-gray-700 mr-2 mb-2"
											>
												<FaStar className="text-yellow-400 mr-1" />
												{role}
											</span>
										))}
									</div>
								</div>
							</div>
						))}
					</div>
				</section>

				{/* Client Testimonials Section */}
				<section className="mb-16 max-w-screen-xl mx-auto">
					<h3 className="text-3xl font-bold mb-8 text-center text-gray-800">
						Testimonials
					</h3>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300">
							<p className="text-gray-700 italic mb-6 leading-relaxed">
								"Latif displays exemplary professionalism and is able to take on
								challenges. He learns quickly and is an asset to any team."
							</p>
						</div>
						<div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300">
							<p className="text-gray-700 italic mb-6 leading-relaxed">
								"Latif is a great co-worker and problem solver. He's quick to
								extend a helping hand and makes a good team player."
							</p>
						</div>
					</div>
				</section>
			</div>

			{/* Footer Section */}
			<footer className="bg-gradient-to-r from-gray-100 to-gray-200 text-gray-700 py-10">
				<div className="max-w-screen-xl mx-auto px-6">
					<div className="border-t border-gray-300 py-8">
						<div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
							{/* Footer Text */}
							<p className="text-lg font-semibold text-center md:text-left">
								&copy; 2024 Latif Hasolli | Let's create something amazing
								together!
							</p>

							{/* Social Media Icons */}
							<div className="flex space-x-6">
								<a
									href="https://www.facebook.com/latif.hasolli/"
									className="bg-gray-700 text-white p-4 rounded-full hover:bg-gray-600 transition-colors duration-300"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="Facebook"
								>
									<FaFacebookF />
								</a>
								<a
									href="https://www.instagram.com/latifhasolli/"
									className="bg-gray-700 text-white p-4 rounded-full hover:bg-gray-600 transition-colors duration-300"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="Instagram"
								>
									<FaInstagram />
								</a>
								<a
									href="https://www.imdb.com/name/nm3740039/"
									className="bg-gray-700 text-white p-4 rounded-full hover:bg-gray-600 transition-colors duration-300"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="IMDb"
								>
									<FaImdb />
								</a>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Profile;
